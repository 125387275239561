@import "../../styles/consts.scss";

.page {
	display: flex;
	// max-height: 93dvh;
	flex-direction: column;
	&.shared {
		height: 100dvh;
		padding-top: 30px;
		max-height: 100dvh;
		padding-bottom: 50px;
		background-color: #272530;
	}
}

.mainRes {
	flex: 1;
	display: flex;
	overflow: hidden;
	justify-content: space-between;
	.left {
		min-width: 40%;
		display: flex;
		position: relative;
		flex-direction: column;
	}
}

.shopResTableWrap {
	gap: 18px;
	width: 100%;
	display: flex;
	overflow: hidden;
	flex-direction: column;
	padding: 0 0 24px 32px;
	.tableTemplate,
	.tableList {
		min-width: 680px;
	}
	.tableTemplate {
		grid-template-columns: 1fr repeat(4, 90px);
	}
	.controls {
		gap: 0.5rem;
		display: flex;
		justify-content: flex-end;
	}
}

.pageHeader {
	display: flex;
	justify-content: space-between;
}

.pageSwitcher {
	gap: 42px;
	display: flex;
	margin: 0 -28px;
	padding: 0 28px;
	border-bottom: 1px solid #45444c;
	button.shared:not(.active) {
		display: none;
	}
	button {
		color: #ffffff;
		margin-bottom: -1px;
		padding-bottom: 15px;
		border-bottom: 2px solid transparent;
		&.active {
			border-bottom: 2px solid $adminBrightColor;
		}
	}
}

.main {
	gap: 18px;
	flex: 1;
	display: flex;
	margin-top: 18px;
	overflow: hidden;
	padding: 18px 28px;
	border-radius: 16px;
	flex-direction: column;
	background-color: $adminBgLightest;
	&.goods {
		overflow: unset;
	}
}

.input,
.datetime {
	border-radius: 10px;
	width: auto;
	input {
		font-size: 14px;
		height: auto;
		border-radius: 10px;
		padding: 6px 14px;
	}
}

.chartWrap {
	flex: 1;
	gap: 16px;
	display: flex;
	overflow-y: hidden;
	padding-top: 100px;
	flex-direction: column;
}

.chart {
	flex: 1;
	height: 100%;
	display: flex;
	overflow: hidden;
	max-height: 300px;
	align-items: center;
}

.chartLegend {
	gap: 1rem;
	display: grid;
	overflow: auto;
	max-height: 600px;
	grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
	@extend .scroll;
}

.chartLegendItem {
	gap: 5px;
	display: flex;
}

// .chartLegendItem {
//   @each $index, $color in $colors {
//     &:nth-child(#{$index}) {
//       .chartLegendCircle {
//         background-color: #{$color};
//       }
//     }
//   }
// }

.chartLegendCircle {
	width: 8px;
	height: 8px;
	flex-shrink: 0;
	margin-top: 8px;
	border-radius: 100px;
	background-color: #fff;
}

.shopSettings {
	gap: 1rem;
	display: grid;
	align-items: flex-start;
	justify-content: space-between;
	grid-template-columns: 1fr 1fr;
	.left {
		gap: 0.5rem;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	}
	.right {
		gap: 0.5rem;
		display: flex;
		flex-wrap: wrap;
		max-width: 400px;
		justify-self: flex-end;
		align-items: flex-start;
		justify-content: flex-end;
	}
}

.select {
	align-self: flex-start;
	&.time {
		.selectBtn {
			min-width: 100px;
		}
	}
	button {
		border-radius: 6px;
	}
}

.selectBtn {
	padding: 6px 14px;
	min-height: 32px;
	min-width: 229px;
}

.title {
	font-size: 24px;
	font-weight: 600;
}

.subTitle {
	font-size: 15px;
	font-weight: 600;
}

.listItemBtn {
	min-width: 111px;
	padding: 6px 8px;
	text-align: center;
	max-width: max-content;
}

.listItemStatus {
	font-size: 12px;
	padding: 4px 8px;
	width: max-content;
	text-align: center;
	border-radius: 10px;
	&.green {
		color: $green;
		background-color: #32d74b1a;
	}
	&.red {
		color: $red;
		background-color: #ff453a1a;
	}
}

.tableTemplate {
	gap: 20px;
	padding: 22px;
	display: grid;
	font-size: 14px;
	min-width: 1240px;
	align-items: center;
	grid-template-columns: 100px 80px 140px 80px 120px 120px minmax(120px, 1fr) 100px 120px 30px;
}
.table {
	// flex: 1;
	display: flex;
	overflow-x: auto;
	min-height: 500px;
	overflow-y: hidden;
	position: relative;
	border-radius: 10px;
	flex-direction: column;
	border: 1px solid #45444c;
	@extend .scroll;
	&.loading {
		pointer-events: none;
		.tableList {
			opacity: 0.6;
		}
	}
}
.tableList {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 85dvh;
	min-width: 1240px;
	@extend .scroll;
}
.tableHead {
	padding-top: 0;
	font-weight: 500;
	color: #8a8a8a;
	padding-bottom: 0;
	@extend .tableTemplate;
	background-color: #272530;

	button {
		display: flex;
		padding: 11px 0;
		text-align: left;
		justify-content: space-between;
		&:hover {
			background: #00000000;
		}
		&::after {
			content: "";
			opacity: 0;
			width: 1rem;
			height: 1rem;
			display: block;
			transform: rotate(180deg);
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url("./arrow-narrow-up-svgrepo-com.svg");
		}
		&.active {
			&::after {
				opacity: 1;
			}
		}
		&.rotated {
			&::after {
				transform: rotate(0);
			}
		}
	}
}

.btnDel {
	padding: 10px;
	&:hover {
		path {
			fill: #fff !important;
		}
	}
}

.btnExcel {
	cursor: pointer;
	&:hover {
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.btnShowMore {
	margin: 0 auto;
	width: max-content;
}

.popupBtns {
	gap: 0.5rem;
	display: grid;
	margin-top: 1rem;
	grid-template-columns: 1fr 1fr;
}

.editGoodPopup h3 {
	text-align: center;
}

.btnShare {
	position: relative;
}
.shareMess {
	z-index: 999;
	padding: 10px;
	position: absolute;
	border-radius: 10px;
	top: calc(100% + 5px);
	right: calc(100% + 5px);
	border: 1px solid #fff;
	border-top-right-radius: 0;
	background-color: $adminBrightColor;
}

.tableRow {
	@extend .tableTemplate;
	padding-top: 8px;
	word-break: break-all;
	padding-bottom: 8px;
	border-top: 1px solid #45444c;
	&:first-child {
		border-top: none;
	}
	&:last-child {
		border-bottom: 1px solid #45444c;
	}
	&.lastRow {
		border-bottom: none;
	}
	&:hover {
		background-color: #00000005;
		.btnEdit {
			path {
				fill: #000;
			}
		}
	}
	button {
		margin: 0 !important;
	}
	button:hover {
		path {
			fill: #000;
		}
	}
	&.new {
		opacity: 0.6;
	}
}
.table.mob {
	display: none;
}
//

.mobList {
	gap: 10px;
	display: flex;
	flex-direction: column;
}

.mobItem {
	border-radius: 8px;
	overflow: hidden;
	border: 1px solid #45444c;
}

.mobItemHeader {
	gap: 1rem;
	display: flex;
	align-items: center;
	// padding: 8px 12px;
	padding-left: 12px;
	background-color: #272530;
	justify-content: space-between;
	border-bottom: 1px solid #272530;
	h3 {
		font-size: 12px;
	}
	.btnDel {
		padding: 8px 12px;
	}
}

.mobItemInfo {
	display: flex;
	padding: 8px 10px 2px 10px;

	flex-direction: column;
	.grey {
		color: #8a8a8a;
		font-size: 11px;
		margin-bottom: 2px;
	}
	.infoItem {
		font-size: 12px;
		margin-bottom: 8px;
		word-break: break-all;
	}
}
.subTitle.mob {
	display: none;
}
.header {
	display: flex;
	justify-content: flex-end;
}
.emptyMes {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	text-align: center;
	transform: translateY(-50%);
}

// shopGoods

.shopGoodsWrap {
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.btns {
		gap: 12px;
		display: flex;
		align-items: center;
	}
	.shopGoods {
		gap: 8px;
		display: flex;
		margin-top: 23px;
		flex-direction: column;
		label {
			font-size: 13px;
			color: #8a8a8a;
		}
		.input {
			border-radius: 6px;
		}
		.inputWrap {
			max-width: 140px;
			flex-shrink: 0;
		}
		input {
			height: 38px;
		}
		li {
			gap: 18px;
			display: flex;
			padding: 13px 18px;
			border-radius: 10px;
			align-items: flex-start;
			background-color: #272530;
		}
		.textarea {
			// max-width: 360px;
		}
		// textarea {
		//   height: 22px;
		//   min-height: 22px;
		// white-space: pre-wrap;
		// }

		white-space: pre-wrap;
		.inputImg {
			width: 40px;
			height: 40px;
			margin-top: 22px;
		}
	}
}

.shopSettingsWrap .top,
.top.settings {
	display: flex;
	grid-area: 1/1/2/3;
	align-items: center;
	justify-content: space-between;
	.btns {
		gap: 12px;
		display: flex;
		align-items: center;
	}
}

.shopSettingsWrap {
	gap: 28px;
	display: grid;
	grid-template-columns: 0.7fr 0.3fr;
}

.shopSettingsR,
.shopSettingsL {
	gap: 28px;
	display: flex;
	flex-direction: column;
}

.shopSettingsL {
	padding-bottom: 200px;
}

.shopSettingsBlock {
	display: flex;
	border-radius: 10px;
	flex-direction: column;
	background-color: #272530;
	.subTitle {
		font-size: 13px;
		padding: 5px 22px;
		font-weight: normal;
		border-bottom: 1px solid #45444c;
	}
}

.btnShowMoreWrap {
	position: relative;
}

.showMoreSettings {
	right: 20px;
	left: -200px;
	bottom: calc(100% - 22px);
	display: flex;
	position: absolute;
	border-radius: 6px;
	flex-direction: column;
	background-color: #45444c;
	.btnClose {
		width: 30px;
		height: 30px;
		padding: 7px;
		margin-bottom: -7px;
		// padding-bottom: 0;
		align-self: flex-end;
		svg {
			width: 12px;
			height: 12px;
		}
		path {
			fill: #fff;
		}
	}
	.input {
		border-color: #8a8a8a;
	}
	.inputWrapClassName {
		width: auto;
		margin: 0 14px 12px 14px;
	}
	.switcherWrap {
		display: flex;
		padding: 12px 14px;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #8a8a8a33;
		border-bottom: 1px solid #8a8a8a33;
		span,
		label {
			color: #fff;
			font-size: 13px;
		}
	}
	.btnDel {
		color: #fff;
		display: flex;
		font-size: 12px;
		padding: 12px 14px;
		align-items: center;
		justify-content: space-between;
	}
}

.btnShowMore {
	width: 40px;
	height: 40px;
	display: flex;
	flex-shrink: 0;
	margin-top: 21px;
	align-items: center;
	justify-content: center;
}

.shopSettingsContent {
	padding: 18px 22px;
	&.text {
		row-gap: 28px;
		column-gap: 38px;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}
	&.btns {
		gap: 18px;
		display: flex;
		flex-direction: column;
	}
	.checkbox {
		p {
			font-size: 13px;
			color: #8a8a8a;
		}
	}
	.text,
	.textGrey {
		font-size: 12px;
	}
	.textGrey {
		color: #8a8a8a;
	}
}

.inputWithImg {
	display: grid;
	// max-width: 420px;
	grid-template-columns: 1fr 40px;
	width: 100%;
	column-gap: 12px;
	row-gap: 3px;
	.inputImg {
		width: 40px;
		height: 40px;
		margin-top: 22px;
	}
	.textarea {
		grid-area: 1/1/2/2;
	}
	// textarea {
	// padding: 0;
	// height: 22px;
	// min-height: 22px;
	// padding-top: 3px;
	// }
}

@media (max-width: 1000px) {
	.subTitle.desc {
		display: none;
	}
	.subTitle.mob {
		display: block;
	}
	.table {
		display: none;
	}
	.main {
		padding: 18px 10px;
		overflow-x: hidden;
	}
	.table.mob {
		display: block !important;
	}
	.title {
		padding: 5px;
		font-size: 18px;
	}
	.page {
		width: 100%;
		max-height: unset;
		padding-left: 5px;
		padding-right: 5px;
		overflow-x: hidden;
		&.shared {
			height: unset;
			max-height: unset;
			padding-top: 30px;
			min-height: 100dvh;
			padding-bottom: 30px;
		}
	}
	.shopSettings {
		display: flex;
		flex-direction: column-reverse;
		.selectBtn {
			padding: 12px 16px;
		}
		.right {
			width: 100%;
			display: grid;
			grid-template-columns: 0.5fr 0.5fr;
			.select {
				width: 100%;
				grid-area: 2/1/3/3;
			}
		}
		.left {
		}
	}
	.input {
		padding: 4px;
	}
	.listItemBtn {
		max-width: none;
		padding: 8px 4px;
		margin: 0 10px 10px 10px;
		width: calc(100% - 20px);
	}
	.table {
		border: none;
	}
	// statistic
	.mainRes {
		gap: 1rem;
		flex-direction: column;
		.left {
			min-height: 390px;
		}
	}
	.controls {
		gap: 0.5rem;
		display: grid;
		grid-template-columns: 0.5fr 0.5fr;
		.select {
			grid-area: 2/1/3/3;
		}
	}
	.chartWrap {
		padding-top: 20px;
	}
	.chartLegend {
		max-height: 150px;
	}
	.shopResTableWrap {
		padding: 0;
		min-height: 400px;
	}
	.chartLegendItem {
		align-items: center;
	}
	.chartLegendItem p {
		font-size: 12px;
	}
	.chartLegendCircle {
		margin-top: 0;
	}
}
