@import "../../styles/consts.scss";

input[type="datetime-local"] {
	width: 170px;
	font-size: 13px;
	padding: 6px 14px;
}

.inputBlock {
	min-height: 100px;
	// padding: 8px 12px;
	// border-radius: 6px;
	// border: 1px solid #45444c;

	&__wrap {
		width: 100%;
		label {
			display: block;
			font-size: 13px;
			color: #8a8a8a;
			margin-bottom: 6px;
		}
		input {
			width: 100%;
			outline: none;
			color: #fff;
			border-radius: 6px;
			padding: 12px 14px;
			padding-right: 45px;
			border: 1px solid #45444c;
		}
	}
	&__valueList {
		gap: 8px;
		display: flex;
		flex-wrap: wrap;
		margin-top: 8px;
	}
	&__valueItem {
		gap: 8px;
		padding: 8px;
		color: #fff;
		display: flex;
		font-size: 15px;
		border-radius: 6px;
		align-items: center;
		background-color: #45444c;
		path {
			stroke: #8a8a8a;
		}
		&:hover {
			background-color: #525159;
		}
	}
	&__inputWrap {
		// max-width: 360px;
		position: relative;
	}
	&__btnSave {
		gap: 5px;
		top: 50%;
		right: 5px;
		height: 32px;
		padding: 8px;
		display: flex;
		color: #fff;
		border-radius: 4px;
		position: absolute;
		align-items: center;
		background-color: #272530;
		transform: translateY(-50%);
		p {
			font-size: 13px;
		}
	}
}

.input {
	&__wrap {
		width: auto;
	}
	&__inputWrap {
		display: flex;
		position: relative;
		border-radius: 6px;
		// border-radius: 6px;
		align-items: center;
		border: 1px solid $adminDarkestColor;
		// border: 1px solid $adminDarkestColor;
	}
	&__innerLabel {
		padding-left: 14px;
		color: #8a8a8a;
		font-size: 13px;
		white-space: nowrap;
	}
	// &__input::placeholder {
	// }
	&__input:focus {
		outline: none;
		border-color: $adminLightColor;
	}
	&__input {
		padding: 0;
		width: 100%;
		height: 40px;
		font-size: 1rem;
		padding: 12.5px 16px;
		color: $adminLightColor;
		transition: $transition;
		border: none;
	}
	&__input.withReset {
		padding-right: 40px;
	}
	&__reset,
	&__show {
		top: 50%;
		right: 4px;
		z-index: 99;
		padding: 10px;
		position: absolute;
		transform: translateY(-50%);
	}
	&__show {
		svg {
			width: 20px;
			height: 20px;
		}
		path {
			transition: $transition;
			stroke: $adminDarkColor;
		}
	}
	&__reset {
		path {
			fill: $adminDarkColor;
			transition: $transition;
		}
	}
	&__show:hover {
		path {
			stroke: $adminLightColor;
		}
	}
	&__reset:hover {
		path {
			fill: $adminLightColor;
		}
	}
	&__label {
		gap: 10px;
		display: flex;
		align-items: center;
		margin-bottom: 6px;
	}
	&__wrap {
		width: 100%;
	}
	&__input.error {
		border-color: $red;
	}
	&__textError {
		color: $red;
		font-size: 14px;
		position: absolute;
	}
}

.btnLoader,
.loader {
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
	&::before {
		content: "";
		width: 75px;
		height: 75px;
		display: block;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a11" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="%233E99FF"></stop><stop offset=".3" stop-color="%233E99FF" stop-opacity=".9"></stop><stop offset=".6" stop-color="%233E99FF" stop-opacity=".6"></stop><stop offset=".8" stop-color="%233E99FF" stop-opacity=".3"></stop><stop offset="1" stop-color="%233E99FF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(%23a11)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="%233E99FF" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>');
	}
}
.btnLoader {
	width: 100%;
	height: 100%;
	&::before {
		width: 100%;
		height: 100%;
		background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a11" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="%23ffffff"></stop><stop offset=".3" stop-color="%23ffffff" stop-opacity=".9"></stop><stop offset=".6" stop-color="%23ffffff" stop-opacity=".6"></stop><stop offset=".8" stop-color="%23ffffff" stop-opacity=".3"></stop><stop offset="1" stop-color="%23ffffff" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(%23a11)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="%23ffffff" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>');
	}
}

.checkbox {
	gap: 10px;
	width: 20px;
	height: 20px;
	display: flex;
	flex-shrink: 0;
	border-radius: 4px;
	align-items: center;
	justify-content: center;
	border: 1px solid #8a8a8a;
	&__wrap {
		gap: 12px;
		display: flex;
		p {
			margin-top: 2px;
			font-size: 1rem;
			color: $adminLightColor;
		}
		.daw {
			width: 16px;
			height: 16px;
			display: none;
			svg {
				width: 100%;
				height: 100%;
			}
			path {
				fill: $adminBrightColor;
			}
		}
	}
}
.checkbox.on {
	border-color: $adminBrightColor;
	.daw {
		display: block;
	}
}

.switcher {
	gap: 10px;
	display: flex;
	align-items: center;
	span {
		font-size: 1rem;
	}
	.bg {
		width: 40px;
		padding: 2px;
		display: flex;
		border-radius: 12px;
		justify-content: flex-start;
		background-color: $adminDarkestColor;
		border: 1px solid #8a8a8a;
	}
	.round {
		width: 16px;
		height: 16px;
		border-radius: 100px;
		background-color: #fff;
	}
}
.switcher.on {
	.bg {
		justify-content: flex-end;
		background-color: #32d74b;
	}
}

.select {
	&__label {
		display: block;
		margin-bottom: 8px;
	}
	&__selectedList {
		flex: 1;
		gap: 6px;
		display: flex;
		flex-wrap: wrap;
	}
	&__selected {
		gap: 5px;
		display: flex;
		padding: 2px 8px;
		border-radius: 4px;
		background-color: #fff;
		color: #16191c !important;
		path {
			fill: $adminDarkColor !important;
		}
		svg {
			transform: none !important;
		}
	}
	&__wrap {
		gap: 6px;
		display: flex;
		position: relative;
		flex-direction: column;
	}
	&__wrap.noBorder {
		.select {
			&__btnShow,
			&__btnAdd {
				border: none;
				color: $adminLightColor;
				path {
					fill: $adminLightColor;
				}
			}
		}
	}
	&__wrap.many {
		align-items: flex-end;
		.select {
			&__btnShow {
				flex: 1;
				display: flex;
				min-height: 46px;
				padding: 12.5px 16px;
				justify-content: space-between;
			}
			&__btnAdd {
				padding: 15px;
			}
			&__btnShow,
			&__btnAdd {
				border-radius: 6px;
			}
		}
	}
	&__btnShow.disabled {
		opacity: 0.75;
		cursor: default;
		pointer-events: none;
	}
	&__btnShow {
		width: 100%;
		display: flex;
		font-size: 13px;
		min-height: 40px;
		padding: 8px 10px;
		border-radius: 10px;
		align-items: center;
		color: $adminDarkColor;
		justify-content: space-between;
		border: 1px solid $adminDarkestColor;
		span {
			max-width: 100%;
			display: block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		svg {
			flex-shrink: 0;
			transition: $transition;
		}
		path {
			transition: $transition;
			fill: $adminDarkColor;
		}
	}
	&__btnAdd {
		@extend .btnAdd;
	}
	&__btnShow {
		gap: 10px;
		// span {
		// }
		img {
			width: 14px;
			height: 16px;
		}
		svg {
			margin-top: 2px;
			transform: rotate(180deg);
		}
	}
	&__btnShow.selected {
		color: $adminLightColor;
	}
	&__btnShow.showed {
		// border-color: $adminLightColor;
		color: $adminLightColor;
		path {
			fill: $adminLightColor;
		}
		svg {
			transform: rotate(0);
		}
	}

	&__btnShow:active,
	&__btnAdd:active,
	&__btnShow:hover,
	&__btnAdd:hover {
		// border-color: $adminLightColor;
		color: $adminLightColor;
		path {
			fill: $adminLightColor;
		}
	}
	&__listWrap {
		left: 0;
		z-index: 1000;
		padding: 6px;
		overflow: hidden;
		// min-width: 200px;
		width: 100%;
		position: absolute;
		border-radius: 12px;
		top: calc(100% + 4px);
		background-color: $adminDarkestColor;
		animation: 1 appearance 0.3s ease-in-out;
	}
	&__list {
		margin: 3px 0;
		overflow: auto;
		max-height: 200px;
		&::-webkit-scrollbar {
			width: 3px;
		}
	}
	&__listWrap.many {
		width: 100%;
	}
	&__item {
		cursor: pointer;
		max-width: 100%;
		overflow: hidden;
		padding: 10px 8px;
		border-radius: 6px;
		text-overflow: ellipsis;
		// color: $adminDarkColor;
		transition: $transition;
	}
	&__item.disabled {
		pointer-events: none;
	}
	&__item:active,
	&__item:hover {
		// color: $adminLightColor;
		background-color: #ffffff1a;
	}
}

.nav {
	width: 108px;
	padding: 37px 0;
	border-radius: 20px;
	margin: 0 0 50px 10px;
	background-color: $adminBgDarkest;
	&__title {
		margin: 0 10px;
		max-width: 100%;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
		word-break: break-all;
		color: $adminBrightColor;
	}
	&__title.dark {
		color: $adminDarkColor;
	}
	&__subList,
	&__list {
		gap: 24px;
		display: flex;
		padding: 0 20px;
		margin-top: 28px;
		flex-direction: column;
	}
	&__item {
		position: relative;
	}
	&__subSubList {
		gap: 0.25rem;
		right: -100px;
		display: none;
		padding: 0.25rem;
		position: absolute;
		border-radius: 20px;
		flex-direction: column;
		border: 1px solid #302f38;
		background-color: $adminBgDark;
		button {
			padding: 0.5rem;
			color: #8a8a8a;
			cursor: pointer;
		}
		button:hover {
			color: #fff;
		}
		&.visible {
			display: flex;
		}
	}
	&__item {
		gap: 6px;
		display: flex;
		cursor: pointer;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		&__imgWrap {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			border-radius: 10px;
			position: relative;
			justify-content: center;
			background-color: $adminDarkestColor;
			&.stroke {
				svg path {
					stroke: $adminDarkColor;
				}
			}
		}
		&__imgWrap::after {
			content: "";
			bottom: 0;
			height: 2px;
			width: 28px;
			margin: 0 auto;
			display: block;
			position: absolute;
			border-radius: 10px;
			transition: $transition;
			background-color: transparent;
		}
		svg {
			width: 24px;
			height: 21px;
			path {
				transition: $transition;
				fill: $adminDarkColor;
			}
			rect {
				transition: $transition;
				fill: $adminDarkColor;
			}
		}
		p {
			font-size: 12px;
			width: min-content;
			text-align: center;
			color: $adminDarkColor;
			transition: $transition;
		}
	}
	&__item.actived {
		// cursor: default;
		// pointer-events: none;
		.nav__item__imgWrap::after {
			background-color: #fff;
		}
		.nav__item__imgWrap {
			&.stroke svg path {
				stroke: $adminLightColor;
			}
		}
		svg {
			path {
				fill: $adminLightColor;
			}
			rect {
				fill: $adminLightColor;
			}
		}
		p {
			color: $adminLightColor;
		}
	}
	&__item:active,
	&__item:hover {
		.nav__item__imgWrap::after {
			background-color: #fff;
		}
	}
	&__subList {
		gap: 18px;
		padding: 0;
		// overflow: auto;
		// overflow-x: hidden;
		// overflow-y: auto;
		// max-height: 340px;
		margin-top: 20px;
		position: relative;
		padding: 12px 10px;
		border-radius: 10px;
		background-color: $adminBg;
		@extend .scroll;
		&::before {
			top: -6px;
			left: 50%;
			content: "";
			width: 12px;
			height: 12px;
			position: absolute;
			background-color: $adminBg;
			transform: translateX(-50%) rotate(45deg);
		}
	}
}

.popUp {
	&__wrapper {
		top: 0;
		left: 0;
		right: 0;
		z-index: 9999;
		height: 100vh;
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #00000099;
	}
	&__window {
		display: flex;
		overflow: hidden;
		border-radius: 16px;
		flex-direction: column;
		padding: 14px 38px 38px 38px;
		background-color: $adminBgLightest;
	}
	&__btnCloseWrap {
		display: flex;
		margin-right: -24px;
		justify-content: flex-end;
	}
	&__btnClose {
		z-index: 99;
		padding: 10px;
		justify-self: flex-end;
		svg {
			width: 14px;
			height: 14px;
		}
		path {
			fill: $adminDarkColor;
			transition: $transition;
		}
	}
	&__btnClose:hover {
		path {
			fill: $adminLightColor;
		}
	}
}

.textareaWrap {
	gap: 6px;
	width: 100%;
	display: flex;
	flex-direction: column;

	label {
		font-size: 13px;
		color: $adminDarkColor;
	}
	&.withReset {
		.textarea {
			padding-right: 34px;
		}
	}
	.textareaSubWrap {
		padding: 6px;
		min-width: 200px;
		min-height: 40px;
		border-radius: 6px;
		position: relative;
		border: 1px solid $adminDarkestColor;
	}
	.input__reset {
		top: 19px;
		right: 10px;
		padding: 4px;
	}
	.textarea {
		overflow: hidden;
		// resize: vertical;
		// white-space: pre-wrap;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 9px 14px;
		// padding-top: 9px;
		min-height: 40px;
		overflow: hidden;
		position: absolute;
		height: max-content;
		white-space: pre-wrap;
		word-break: break-word;
	}

	.textarea,
	textarea {
		font-size: 1rem;
		line-height: 120%;
		font-family: "Inter", sans-serif;
	}

	.textarea.clone {
		opacity: 0;
		// position: relative;
	}
}

.inputImgWrap {
	width: 40px;
	height: 40px;
	display: flex;
	flex-shrink: 0;
	cursor: pointer;
	overflow: hidden;
	object-fit: cover;
	position: relative;
	border-radius: 6px;
	align-items: center;
	justify-content: center;
	transition: $transition;
	border: 1px dashed $adminDarkestColor;
	&.fill {
		border: 0;
	}
	path {
		fill: $adminDarkColor;
		transition: $transition;
	}
	label {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		z-index: 999;
		cursor: pointer;
		position: absolute;
	}
	input {
		width: 0;
		height: 0;
		opacity: 0;
	}
	.img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		-o-object-fit: cover;
		object-position: center;
	}
}
.inputImgWrap.disabled,
.inputImgWrap.disabled * {
	cursor: default;
}
.inputImgWrap:not(.disabled):hover
// .inputImgWrap:hover
{
	border-color: $adminLightColor;
	path {
		fill: $adminLightColor;
	}
}

@keyframes appearance {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
