@import "../../styles/consts.scss";

.editPopUp {
	&__title {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 28px;
	}
	&__inputList {
		padding-right: 10px;
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 500px;
		padding: 5px 0;
	}
	&__switcherWrap {
		min-width: 200px;
		white-space: nowrap;
		margin-bottom: 10px;
		span {
			font-size: 14px;
		}
	}
	&__addAns {
		gap: 6px;
		display: flex;
		margin-top: 24px;
		padding: 11px 16px;
		border-radius: 10px;
		align-items: center;
		color: $adminDarkColor;
		transition: $transition;
		border: 1px solid $adminDarkColor;

		path {
			fill: $adminDarkColor;
			transition: $transition;
		}
		&.full {
			width: 100%;
			justify-content: center;
		}
	}
	&__addAns:hover {
		color: $adminLightColor;
		border: 1px solid $adminLightColor;
		path {
			fill: $adminLightColor;
		}
	}
	&__btnList {
		gap: 10px;
		display: flex;
		margin-top: 28px;
	}
	&__inputWrap {
		gap: 10px;
		width: 760px;
		display: flex;
		margin-top: 24px;
		align-items: flex-end;
		&:first-child {
			margin-top: 0;
		}
		label {
			color: $adminDarkColor;
		}
		input {
			color: $adminLightColor;
		}
		.textarea {
			width: 100%;
		}
	}
}

.quiz {
	&__page {
		overflow: hidden;
	}
	&__page.loading {
		opacity: 0.5;
		pointer-events: none;
	}
	&__listWrap {
		flex: 1;
		display: flex;
		max-width: 70%;
		position: relative;
		align-items: center;
	}
	&__list {
		width: 100%;
	}
	&__item {
		width: auto;
		padding: 13px 14px;
		flex-shrink: 0;
		cursor: pointer;
		border-radius: 16px;
		border: 1px solid transparent;
		background-color: $adminBgLightest;
		&__top {
			gap: 20px;
			display: flex;
			justify-content: space-between;
			p {
				height: 36px;
				font-size: 15px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				word-break: break-all;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			button path {
				transition: $transition;
				stroke: $adminDarkColor;
			}
			button:active,
			button:hover {
				path {
					stroke: $adminLightColor;
				}
			}
		}
		&__btnCopy {
			width: 18px;
			height: 18px;
			flex-shrink: 0;
			svg {
				width: 100%;
				height: 100%;
			}
			z-index: 9;
		}
		&__bottom {
			gap: 20px;
			display: flex;
			margin-top: 12px;
			justify-content: space-between;
			p {
				font-size: 13px;
				color: $adminDarkColor;
			}
		}
	}
	&__item.actived {
		border-color: $adminBrightColor;
	}
	&__btnBack,
	&__btnNext {
		top: 30%;
		z-index: 9;
		width: 32px;
		height: 32px;
		position: absolute;
		border-radius: 100px;
		background-size: 50%;
		background-position: center;
		background-repeat: no-repeat;
		background-color: #3e99ff1a;
		border: 1px solid #3e99ff1a;
		background-image: url(../../imgs/arrowNext.svg);
	}
	&__btnBack {
		left: -16px;
		transform: rotate(180deg);
	}
	&__btnNext {
		right: -16px;
	}
	&__header {
		gap: 50px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		&__btns {
			gap: 10px;
			display: flex;
			justify-content: flex-end;
		}
		&__btn {
			width: 123px;
			flex-shrink: 0;
			min-height: 64px;
			border-radius: 16px;
			border: 1px solid transparent;
			background-color: $adminBgLightest;
			&:disabled {
				cursor: default;
			}
			p {
				width: 100%;
				font-size: 14px;
				text-align: center;
				color: $adminLightColor;
			}
			&__imgWrap {
				width: 18px;
				height: 18px;
				margin: 0 auto;
				margin-bottom: 9px;
				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	&__main {
		margin-top: 18px;
		overflow: hidden;
		border-radius: 16px;
		background-color: $adminBgLightest;
		&.navHidden {
			margin-top: 0;
			border-radius: 0px;
			background-color: transparent;
			.quiz__main__nav {
				display: none;
			}
		}
		// main nav
		&__nav {
			display: flex;
			padding-left: 28px;
			padding-right: 15px;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #4b4e64;
		}
		&__navItemList {
			gap: 42px;
			display: flex;
		}
		&__navItem {
			padding: 18px 0;
			font-size: 15px;
			margin-bottom: -1px;
			color: $adminLightColor;
			border-bottom: 2px solid transparent;
		}
		&__navItem.actived {
			border-bottom-color: $adminBrightColor;
		}
		&__navBtnList {
			gap: 8px;
			display: flex;
		}

		// main body
		&__body {
			margin: 28px 8px;
			overflow-y: auto;
			max-height: 650px;
			padding: 0 28px 0 20px;
			.inputLabel {
				gap: 10px;
				display: flex;
				align-items: center;
			}
			.notes {
				gap: 10px;
				display: flex;
				margin-left: 10px;
				align-items: center;
				.title {
					font-size: 14px;
				}
				.item {
					font-size: 13px;
					padding: 1px 6px;
					border-radius: 6px;
					background-color: #45444c;
				}
			}
			.inputWrap {
				max-width: 100%;
				width: 560px;
				label {
					font-size: 13px;
					color: $adminDarkColor;
				}
				input {
					padding-top: 11px;
					padding-bottom: 11px;
					color: $adminLightColor;
				}
			}
			.switchWrap {
				margin-top: 20px;
			}
			.title {
				font-size: 20px;
				font-weight: 500;
				margin-top: 38px;
				margin-bottom: 18px;
				display: inline-block;
			}
			.textList {
				gap: 22px;
				display: flex;
				// margin-top: 18px;
				flex-direction: column;
			}
			.textItem {
				gap: 10px;
				display: flex;
				align-items: flex-end;
			}
			.btnRowList {
				gap: 24px;
				overflow: auto;
				display: flex;
				margin-left: 8px;
			}
			.btnRow {
				gap: 8px;
				display: flex;
				padding: 14px 0;
				font-size: 14px;
				align-items: center;
				color: $adminLightColor;
				span {
					white-space: nowrap;
				}
				img {
					width: 16px;
					height: 16px;
				}
			}

			.questions {
				.answerListWrap {
					gap: 8px;
					width: 100%;
					overflow: hidden;
					margin-top: 15px;
					flex-direction: column;
					align-items: flex-start;
				}
				.answerListTitle {
					gap: 10px;
					display: flex;
					align-items: center;
				}
				.answerListTitle button,
				.answerListTitle {
					font-size: 13px;
					line-height: 100%;
					color: $adminDarkColor;
				}
				.answerListTitle button {
					font-size: 12px;
					font-weight: 500;
					padding: 5px 14px;
					border-radius: 8px;
					color: $adminLightColor;
					border: 1px solid #ffffff1a;
					background-color: #ffffff1a;
				}
				// .answerListTitle button:hover {
				//   color: $adminLightColor;
				// }
				.answerList {
					gap: 10px;
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					padding-bottom: 5px;
					align-items: center;
				}
				.answer {
					display: flex;
					align-items: center;
					border-radius: 10px;
					background-color: #fff;
					padding: 0 5px 0 10px;
					border: 2px solid transparent;
				}
				.answer.active {
					border-color: $green;
				}
				.answer.active::before {
					content: "";
					width: 18px;
					height: 18px;
					flex-shrink: 0;
					display: block;
					margin-right: 8px;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM13.1328 6.71444C13.3617 6.42018 13.3087 5.99609 13.0144 5.76722C12.7202 5.53835 12.2961 5.59136 12.0672 5.88562L8.46089 10.5223C8.3807 10.6254 8.22986 10.6388 8.13277 10.5514L5.85156 8.49831C5.57446 8.24892 5.14767 8.27139 4.89828 8.54848C4.6489 8.82557 4.67136 9.25237 4.94845 9.50175L7.22967 11.5548C7.90928 12.1665 8.96517 12.0728 9.52652 11.3511L13.1328 6.71444Z" fill="%2332D74B"/></svg>');
				}
				.answerText {
					font-size: 15px;
					font-weight: 500;
					color: #1e1d2a;
					margin-right: 10px;
				}
				.answerBtn {
					padding: 5px;
					svg {
						width: 16px;
						height: 16px;
					}
				}
				.inputPointsWrap {
					flex-shrink: 0;
				}
				.titleWrap {
					display: flex;
					margin-bottom: 24px;
					align-items: center;
				}
				.title {
					margin: 0;
					margin-right: 10px;
				}
				.btnAdd {
					margin-left: 10px;
				}
				.inputWrap {
					label {
						color: $adminLightColor;
					}
				}
				.inputPointsWrap {
					font-size: 14px;
					padding: 12px 14px;
					padding-right: 30px;
					border-radius: 6px;
					position: relative;
					color: $adminDarkColor;
					border: 1px solid $adminDarkestColor;
					input {
						width: 40px;
						border: none;
						outline: none;
						margin-left: 6px;
						color: $adminLightColor;
					}
					.inputPoints__reset {
						top: 50%;
						right: 4px;
						z-index: 99;
						padding: 10px;
						position: absolute;
						transform: translateY(-50%);
						path {
							fill: $adminDarkColor;
							transition: $transition;
						}
					}
					.inputPoints__reset:hover {
						path {
							fill: $adminLightColor;
						}
					}
				}
			}
		}
	}
}

.inboxForm {
	gap: 1rem;
	display: flex;
	align-items: center;
	flex-direction: column;
	&__row {
		gap: 0.5rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

.quiz__main__body {
	background-color: $adminBgLightest;
}

.quizQuestionsDB {
	.header {
		display: flex;
		margin-top: 8px;
		align-items: center;
		justify-content: space-between;
		button {
			font-size: 14px;
			margin-left: 10px;
			// padding: 8px 18px;
		}
	}
	.title {
		font-size: 24px;
		font-weight: 600;
		margin-right: 10px;
	}
	// .main {
	//   margin-top: 18px;
	//   overflow-y: auto;
	//   max-height: 640px;
	//   padding: 32px 28px;
	//   border-radius: 20px;
	//   background-color: $adminBgLightest;
	// }
	// .questList {
	//   gap: 22px;
	//   display: flex;
	//   flex-direction: column;
	// }
	// .inputWrap {
	//   max-width: 460px;
	//   label {
	//     font-size: 13px;
	//   }
	// }
}

.popupDB {
	width: 800px;
	max-width: 100%;
	&__list {
		gap: 15px;
		display: flex;
		flex-direction: column;
	}
	&__row {
		border-radius: 10px;
		padding: 18px 28px;
		background-color: #272530;
		max-width: 750px;
		display: flex;
		flex-direction: column;
		.popupDB__inputWrap .editPopUp__switcherWrap {
			margin-bottom: 0;
		}
	}
	&__inputWrap {
		gap: 30px;
		display: flex;
		align-items: center;
	}
}

.quizEdit {
	font-size: 14px;
	.editRowList {
		gap: 28px;
		display: flex;
		margin-top: 26px;
		flex-direction: column;
	}
	.editRow {
		gap: 20px;
		display: grid;
		grid-template-columns: 0.25fr 0.25fr 0.5fr;
	}
	.editRowBtn {
		padding: 12px;
		cursor: pointer;
	}
	.note {
		font-size: 12px;
		margin-top: 16px;
		color: $adminDarkColor;
	}
}
