@import "../../styles/consts.scss";

.header {
	gap: 10px;
	display: flex;
	flex-direction: column;
	// align-items: center;
	// justify-content: space-between;
	.title {
		font-size: 24px;
		font-weight: 600;
	}
}

.headerItemWrap {
	flex: 1;
	gap: 20px;
	display: flex;
	max-width: 100%;
	justify-content: space-between;
}

.btnAddItem {
	width: 123px;
	flex-shrink: 0;
	min-height: 64px;
	border-radius: 16px;
	background-color: #302f38;
	border: 1px solid transparent;
	p {
		width: 100%;
		font-size: 14px;
		text-align: center;
		color: #fff;
	}
	.btnAddItemImgWrap {
		width: 18px;
		height: 18px;
		margin: 0 auto;
		margin-bottom: 9px;
	}
}

.headerItemListWrap {
	width: 100%;
	display: flex;
	position: relative;
	align-items: center;
	max-width: calc(100% - 200px);
}

.headerItemList {
	width: 100%;
}

.headerItem {
	width: auto;
	padding: 13px 14px;
	flex-shrink: 0;
	cursor: pointer;
	border-radius: 16px;
	border: 1px solid transparent;
	background-color: $adminBgLightest;
	&__top {
		gap: 20px;
		display: flex;
		justify-content: space-between;
		.btns {
			display: flex;
			margin: -10px 0;
			flex-direction: column;
		}
		button {
			z-index: 9;
			padding: 5px;
			flex-shrink: 0;
		}
		path {
			transition: $transition;
		}
		svg {
			width: 18px;
			height: 18px;
		}
		p {
			height: 36px;
			font-size: 15px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			word-break: break-all;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	&__btnCopy {
		path {
			stroke: $adminDarkColor;
		}
		&:active,
		&:hover {
			path {
				stroke: $adminLightColor;
			}
		}
	}
	&__btnDel {
		margin-right: -12px;
		path {
			fill: $adminDarkColor;
		}
		&:active,
		&:hover {
			path {
				fill: $adminLightColor;
			}
		}
	}
	&__bottom {
		gap: 20px;
		display: flex;
		margin-top: 12px;
		justify-content: space-between;
		p {
			font-size: 13px;
			color: $adminDarkColor;
		}
	}
	&.active {
		border-color: $adminBrightColor;
	}
}

.swiperBtn {
	&Back,
	&Next {
		top: 30%;
		z-index: 9;
		width: 32px;
		height: 32px;
		position: absolute;
		border-radius: 100px;
		background-size: 50%;
		background-position: center;
		background-repeat: no-repeat;
		background-color: #3e99ff1a;
		border: 1px solid #3e99ff1a;
		background-image: url(../../imgs/arrowNext.svg);
	}
	&Back {
		left: -16px;
		transform: rotate(180deg);
	}
	&Next {
		right: -16px;
	}
}

//

.titleWrap {
	gap: 20px;
	display: flex;
	align-items: center;
}

.subTitle {
	font-size: 20px;
	font-weight: 600;
}

.page {
	overflow-y: visible;
}

.main {
	gap: 18px;
	display: flex;
	margin-top: 18px;
	padding: 18px 28px;
	border-radius: 16px;
	flex-direction: column;
	background-color: $adminBgLightest;
}

.inputImg {
	margin-top: 22px;
}

.pageMainHeader {
	display: flex;
	padding: 0 28px;
	margin: 0 -28px;
	margin-top: -18px;
	justify-content: space-between;
	border-bottom: 1px solid #45444c;
	.btns {
		gap: 12px;
		display: flex;
		padding: 8px 0;
	}
}

.pageSwitcher {
	gap: 42px;
	display: flex;
	button.shared:not(.active) {
		display: none;
	}
	button {
		color: #ffffff;
		padding-top: 14px;
		margin-bottom: -1px;
		padding-bottom: 15px;
		border-bottom: 2px solid transparent;
		&.active {
			border-bottom: 2px solid $adminBrightColor;
		}
	}
}

.row {
	gap: 18px;
	display: flex;
}

.row.text {
	row-gap: 2px;
	display: grid;
	column-gap: 18px;
	grid-template-columns: 360px auto;
}

.inputSubTitle {
	font-size: 12px;
	color: #8a8a8a;
}

.textarea {
	max-width: 360px;
}

.inputWrap.mini {
	max-width: 140px;
}

.checkbox {
	p {
		font-size: 13px;
		color: #8a8a8a;
	}
}

.select {
	width: 100%;
	max-width: 360px;
	button {
		border-radius: 6px;
		span {
			font-size: 14px;
			line-height: 100%;
		}
	}
}

.questionList {
	display: flex;
	padding-bottom: 150px;
	flex-direction: column;
}

.btnDel {
	width: 38px;
	height: 38px;
	path {
		fill: #8a8a8a;
	}
}

.question {
	&::after {
		content: "";
		display: block;
		margin: 28px -28px;
		width: calc(100% + 56px);
		border-bottom: 1px solid #45444c;
	}
	&:last-child::after {
		content: none;
	}
}

.questionLabel {
	gap: 10px;
	display: flex;
	margin-bottom: 4px;
	align-items: center;
	label {
		color: $adminLightColor;
	}

	.switcher {
		gap: 5px;
		margin-left: 10px;
		button {
			transform: scale(0.8);
		}
	}
}

.questionInputWrap {
	gap: 18px;
	display: flex;
	align-items: flex-start;
	.select {
		margin-top: -22px;
	}
}

.questionTextInput {
	max-width: 560px;
	width: 100%;
}

.questionAnswersWrap {
	margin-top: 20px;
}

.questionAnswersTitle {
	gap: 10px;
	display: flex;
	align-items: center;
	span {
		font-size: 13px;
		color: #8a8a8a;
	}
}

.btnEdit {
	@extend .btnGrey;
	font-size: 12px;
	padding: 5px 14px;
}

.questionAnswerList {
	gap: 10px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 14px;
	padding-bottom: 5px;
	align-items: flex-start;
	@extend .scroll;
}

.questionAnswer {
	display: flex;
	align-items: center;
	border-radius: 10px;
	// white-space: pre-wrap;
	padding: 0 5px 0 10px;
	background-color: #fff;
	border: 2px solid transparent;
	&.active {
		border-color: #32d74b;
		&::before {
			content: "";
			width: 18px;
			height: 18px;
			flex-shrink: 0;
			display: block;
			margin-right: 8px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM13.1328 6.71444C13.3617 6.42018 13.3087 5.99609 13.0144 5.76722C12.7202 5.53835 12.2961 5.59136 12.0672 5.88562L8.46089 10.5223C8.3807 10.6254 8.22986 10.6388 8.13277 10.5514L5.85156 8.49831C5.57446 8.24892 5.14767 8.27139 4.89828 8.54848C4.6489 8.82557 4.67136 9.25237 4.94845 9.50175L7.22967 11.5548C7.90928 12.1665 8.96517 12.0728 9.52652 11.3511L13.1328 6.71444Z" fill="%2332D74B"/></svg>');
		}
	}
	p {
		font-size: 15px;
		font-weight: 500;
		color: #1e1d2a;
		margin-right: 10px;
	}
}

.questionAnswerBtn {
	padding: 5px;
}
