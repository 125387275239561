@import "../../styles/consts.scss";

.survey {
	display: flex;
	flex-direction: column;
}

.green {
	color: #32d74b;
}
.red {
	color: #ff453a;
}

.title {
	font-size: 24px;
	font-weight: 600;
}

.main {
	width: 100%;
	height: 100%;
	padding: 28px;
	display: flex;
	margin-top: 18px;
	min-height: 300px;
	max-height: 745px;
	overflow-y: auto;
	position: relative;
	border-radius: 20px;
	flex-direction: column;
	background-color: $adminBgDarkest;
	@extend .scroll;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.headerLeft,
.headerRight {
	display: flex;
	gap: 12px;
}

.headerLeft {
	gap: 20px;
}

.surveyTopForm {
	gap: 18px;
	display: flex;
	flex-direction: column;
	&__bottom,
	&__top {
		gap: 24px;
		display: flex;
	}
	&__bottom {
		margin-left: 52px;
	}
	&__topRight,
	&__topLeft {
		gap: 12px;
		width: 100%;
		max-width: 380px;
		display: flex;
		.inputImg {
			margin-top: 24px;
		}
	}
	&__input {
		width: auto;
		max-width: 140px;
		label {
			color: $adminDarkColor;
			font-size: 13px;
		}
	}
	.inputWrap {
		width: auto;
	}
}

.surveyForm {
	gap: 18px;
	display: flex;
	flex-direction: column;
	.row {
		align-items: flex-start;
	}
}

.inputWrap {
	width: auto;
}

.waitingTimeInput {
	display: flex;
	gap: 1.5rem;
	align-items: flex-end;
}

.waitingTimeCheckbox {
	margin-bottom: 12px;
	p {
		color: $adminDarkColor;
		font-size: 13px;
	}
}

label {
	font-size: 13px;
	color: #8a8a8a;
}

.btnPopupSelect {
	border-radius: 6px;
}

.popup {
	width: 950px;
	margin: 2rem;
	height: 680px;
	display: flex;
	max-width: 90vw;
	max-height: 90dvh;
	flex-direction: column;
	&__textarea {
		max-width: 380px;
	}
	&__input {
		width: auto;
		max-width: 380px;
		label {
			font-size: 13px;
			color: $adminDarkColor;
		}
		&.mini {
			max-width: 140px;
		}
	}
}
.popupContent {
	flex: 1;
	display: flex;
	max-height: 100%;
	flex-direction: column;
}

.step3bottom {
	align-items: flex-end;
	.popupSelect {
		width: 100%;
		max-width: 300px;
	}
}

.btnAddBtn {
	width: max-content !important;
	background: none;
	border: none;
	font-size: 13px;
	// margin-top: -10px;
	// margin-left: -1rem;
	&:hover {
		border: none;
	}
}

.popupMain {
	flex: 1;
	height: 500px;
	display: flex;
	margin: 24px 0;
	overflow: auto;
	max-height: 500px;
	flex-direction: column;
	h2 {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 18px;
	}
}

.btnPopupSelect {
	min-height: 44px;
}

.popupSelect {
	max-width: 300px;
	label {
		font-size: 13px;
		color: $adminDarkColor;
	}
}

.popupBtns {
	display: flex;
	justify-content: space-between;
}

.stepList {
	gap: 38px;
	display: flex;
	margin: -50px -38px 0 -38px;
	padding: 20px 70px 14px 28px;
	justify-content: space-between;
	border-bottom: 1px solid #45444c;
}

.step {
	gap: 6px;
	display: flex;
	font-size: 13px;
	align-items: center;
	color: $adminDarkColor;
	&.active {
		color: $adminLightColor;
		.stepNumb {
			border-color: $adminLightColor;
		}
	}
}

.stepNumb {
	width: 20px;
	height: 20px;
	display: flex;
	font-size: 12px;
	align-items: center;
	border-radius: 100px;
	justify-content: center;
	border: 1px solid $adminDarkColor;
}

.moduleHeader {
	display: flex;
	padding: 2px 10px;
	justify-content: space-between;
	border-bottom: 1px solid #3d3b45;
	.right,
	.left {
		display: flex;
		align-items: center;
	}
}

// modules
.surveyModulesList {
	flex: 1;
	display: flex;
	overflow: auto;
	flex-shrink: 0;
	margin-top: 24px;
	padding-right: 12px;
	flex-direction: column;
}

.surveyModule {
	// height: 74px;
	display: flex;
	max-width: 100%;
	margin-bottom: 10px;
	border-radius: 10px;
	// align-items: center;
	flex-direction: column;
	transition: $transition;
	background-color: $adminBgDark;
}

.name {
	font-size: 15px;
}

.moduleInfo {
	padding: 18px;
	font-size: 15px;
}

.btnRow {
	font-size: 14px;
	color: #8a8a8a;
	margin-right: 24px;
	&:hover {
		color: #fff;
	}
}

.reqBtnList {
	gap: 1rem;
	display: flex;
	flex-wrap: wrap;
}

.btnTrashBin {
	path {
		stroke: #8a8a8a;
		transition: $transition;
	}
	&:hover {
		path {
			stroke: #fff;
		}
	}
}
