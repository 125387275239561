@import '../../styles/consts.scss';

.botList {
  gap: 32px;
  display: flex;
  overflow: auto;
  margin-top: 18px;
  padding-right: 10px;
  max-height: 400px;
  flex-direction: column;
  .botItem {
    display: flex;
    padding: 15px;
    position: relative;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #3e99ff00;
    justify-content: space-between;
    background-color: $adminBgDark;
    &:last-child {
      margin-bottom: 25px;
    }
    &.active {
      border-color: $adminBrightColor;
    }
  }
  .botProject {
    left: 0;
    gap: 8px;
    display: flex;
    font-size: 13px;
    position: absolute;
    align-items: center;
    top: calc(100% + 3px);
  }
  .botProject::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 100px;
    display: inline-block;
  }
  .botProject.green::before {
    background-color: $green;
  }
  .botProject.green::before {
    background-color: $red;
  }
  .botProject.blue {
    color: $adminBrightColor;
  }
  .botProject.blue::before {
    content: none;
  }

  .btnEdit {
    gap: 16px;
    padding: 0;
    display: flex;
    font-size: 13px;
    align-items: center;
    color: $adminDarkColor;
    path {
      fill: $adminDarkColor;
      transition: $transition;
    }
    &:hover {
      color: $adminLightColor;
      path {
        fill: $adminLightColor;
      }
    }
  }
}

.popupChooseBot {
  min-height: 220px;
  position: relative;
}

.popupConfirm {
  max-width: 560px;
  .btnWrap {
    gap: 12px;
    display: flex;
    margin-top: 28px;
  }
}

.btnAdd {
  gap: 6px;
  border: none;
  display: flex;
  font-size: 14px;
  padding: 10px 35px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  color: $adminLightColor;
  border: 1px solid #ffffff1a;
  background-color: #ffffff1a;
  svg {
    width: 10px;
    height: 10px;
    flex-shrink: 0;
  }
  path {
    fill: $adminLightColor;
  }
  p {
    white-space: nowrap;
  }
}

.btnAddBot {
  margin-top: 28px;
}

.controller {
  &__headerWrap {
    gap: 16px;
    display: flex;
    align-items: center;
  }
  &__header {
    font-size: 28px;
    font-weight: 600;
  }
  &__warning {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    p {
      padding: 20px;
      max-width: 320px;
      text-align: center;
      color: $adminDarkColor;
    }
  }
  &__headerNote {
    font-weight: 600;
    padding: 6.5px 10px;
    border-radius: 10px;
    color: $adminBrightColor;
    border: 1px solid #3e99ff1a;
    background-color: #3e99ff1a;
  }
  &__headerNote.dark {
    color: $adminDarkColor;
  }
  &__main {
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 28px;
  }
  &__block {
    display: flex;
    min-height: 325px;
    padding: 38px 34px;
    border-radius: 20px;
    flex-direction: column;
    background-color: $adminBgDarkest;
  }
  h3 {
    font-weight: 500;
  }
  &__service {
    display: flex;
    align-items: center;
    &__list {
      gap: 24px;
      display: flex;
      margin-top: 24px;
      flex-direction: column;
    }
    &__icon {
      width: 18px;
      height: 18px;
    }
    &__name {
      font-size: 16px;
      margin-left: 11px;
    }
    &__status {
      padding: 4px 8px;
      font-size: 12px;
      margin-left: 14px;
      text-align: center;
      border-radius: 10px;
    }
    &__status.success {
      color: $green;
      background-color: #32d74b1a;
    }
    &__status.fail {
      color: $red;
      background-color: #ff453a1a;
    }
    &__aggregate {
      flex: 1;
    }
    &__btn {
      font-size: 14px;
      padding: 8px 14px;
      margin-left: 40px;
      border-radius: 8px;
      color: $adminLightColor;
      path {
        transition: $transition;
        stroke: $adminDarkColor;
      }
    }

    &__btn.refresh svg {
      width: 20px;
      height: 20px;
    }
    &__btn.tune {
      // border: 1px solid $adminDarkColor;
      border: 1px solid #ffffff1a;
      background-color: #ffffff1a;
    }
    &__btn:active,
    &__btn:hover {
      path {
        stroke: $adminLightColor;
      }
    }
    &__btn.tune:active,
    &__btn.tune:hover {
      border: 1px solid $adminLightColor;
    }
  }
}

.diagram {
  flex: 1;
  gap: 50px;
  display: flex;
  margin-top: 24px;
  &__vScale {
    display: flex;
    margin-bottom: 17px;
    flex-direction: column;
    justify-content: space-between;
    &__item {
      color: $adminDarkColor;
    }
  }
  &__hScale {
    gap: 43px;
    display: flex;
    position: relative;
    align-items: flex-end;
  }

  &__hScale::after {
    left: 0;
    right: 0;
    bottom: 25px;
    content: '';
    position: absolute;
    border-top: 2px dashed $adminDarkestColor;
  }
}

.activity {
  &__column {
    width: 48px;
    height: 50px;
    position: relative;
    border-radius: 16px;
    margin-bottom: 20px;
    background-color: $adminDarkestColor;
    &__name {
      font-size: 14px;
      text-align: center;
      color: $adminDarkColor;
    }
    &__valueWrap {
      top: 50%;
      right: -20px;
      overflow: hidden;
      position: absolute;
      border-radius: 10px;
      transform: translateY(-50%);
    }
    &__valueSubWrap {
      width: 100%;
      height: 100%;
      padding: 6px 8px;
      position: relative;
    }
    &__valueSubWrap::before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      position: absolute;
      background: #ffffff24;
      backdrop-filter: blur(3px);
    }
    &__value {
      z-index: 99;
      position: relative;
    }
  }
}

.users {
  &__block {
    padding: 30px 22px;
    border-radius: 20px;
    background-color: $adminBgDark;
    &__list {
      gap: 10px;
      display: flex;
      margin-top: 24px;
    }
    &__imgWrap {
      gap: 5px;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        font-size: 12px;
        margin-top: 4px;
      }
    }
    &__infoWrap {
      gap: 20px;
      display: flex;
      margin-top: 12px;
      flex-direction: column;
    }
    &__name {
      max-width: 180px;
    }
    &__value {
      font-size: 28px;
      font-weight: 600;
    }
    &__value.green {
      color: $green;
    }
  }
}

.popupChooseBot,
.popupAddBot {
  width: 634px;
  max-width: 100%;
  a {
    margin-left: 5px;
    color: $adminBrightColor;
    text-decoration: 1px underline $adminBrightColor;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
  }
  .stepList {
    gap: 12px;
    display: flex;
    margin-top: 28px;
    flex-direction: column;
  }
  .stepItem {
    padding: 10px 18px;
    border-radius: 10px;
    background-color: $adminBgDark;
  }
  .stepItem.input {
    padding-bottom: 18px;
  }
  .btnWrap {
    gap: 10px;
    display: flex;
    margin-top: 28px;
  }
  button {
    padding: 8px 18px;
  }
}
