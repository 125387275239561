@import "../../styles/consts.scss";

.startModules {
	display: flex;
	flex-direction: column;
}

// header
.title {
	font-size: 24px;
	font-weight: 600;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.headerLeft,
.headerRight {
	display: flex;
	gap: 12px;
}

.headerLeft {
	gap: 20px;
}

// main
.main {
	width: 100%;
	height: 100%;
	padding: 28px;
	margin-top: 18px;
	min-height: 300px;
	max-height: 745px;
	overflow-y: auto;
	position: relative;
	border-radius: 20px;
	background-color: $adminBgDarkest;
	@extend .scroll;
}

.empty {
	margin-top: 10px;
	text-align: center;
	color: $adminDarkColor;
}

.startModulesList {
	display: flex;
	flex-direction: column;
}

.quizSelect {
	width: 230px;
}

.startModule {
	// height: 74px;
	display: flex;
	max-width: 100%;
	margin-bottom: 10px;
	border-radius: 10px;
	// align-items: center;
	flex-direction: column;
	transition: $transition;
	background-color: $adminBgDark;
}

.moduleHeader {
	display: flex;
	padding: 2px 10px;
	justify-content: space-between;
	border-bottom: 1px solid #3d3b45;
	.right,
	.left {
		display: flex;
		align-items: center;
	}
}

.name {
	width: 100px;
	font-size: 15px;
	overflow: hidden;
	color: $adminDarkColor;
	text-overflow: ellipsis;
}

.moduleInfo {
	flex: 1;
	gap: 24px;
	padding: 15px;
	display: flex;
	//   overflow: hidden;
	align-items: center;
	.inputImg {
		width: 38px;
		height: 38px;
	}
}

.moduleInfoContentWrap {
	align-items: flex-start;
}

.moduleInfoContent {
	gap: 5px;
	display: flex;
	flex-direction: column;
}

.moduleInfoWrap {
	gap: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.moduleInfoHead {
	width: 100%;
	justify-content: space-between;
}

.moduleInfoTitle {
	font-size: 13px;
	color: #8a8a8a;
}

.statusWrap {
	font-size: 12px;
	padding: 2px 6px;
	border-radius: 100px;
	&.green {
		color: $green;
		background-color: #32d74b1a;
	}
	&.red {
		color: $red;
		background-color: #ff453a1a;
	}
}

.greetingBtnsList {
	overflow: auto;
	padding: 14px 0;
}

.btnTrashBin {
	padding: 10px;
	path {
		transition: $transition;
		stroke: $adminDarkColor;
	}
}

.btnTrashBin:hover {
	path {
		stroke: $adminLightColor;
	}
}

// greeting
.greetingText {
	max-width: 310px;
	overflow: hidden;
	margin-left: 2px;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.btnRow {
	gap: 8px;
	display: flex;
	font-size: 14px;
	white-space: nowrap;
	color: $adminDarkColor;
	transition: $transition;
}
.btnRow:hover {
	color: $adminLightColor;
}

.popupEditGreeting {
	gap: 28px;
	width: 564px;
	display: flex;
	align-items: center;
	flex-direction: column;
	.inputImg {
		border: 0;
		width: 58px;
		height: 58px;
		svg {
			width: 100%;
			height: 100%;
		}
		path {
			fill: $adminDarkestColor;
		}
	}
	.inputImg:hover {
		path {
			fill: $adminDarkColor;
		}
	}
	.inputImgWrap {
		gap: 5px;
		display: flex;
		cursor: pointer;
		align-items: center;
		flex-direction: column;
		color: $adminDarkColor;
		transition: $transition;
		&:hover {
			color: $adminLightColor;
		}
	}
}

.textareaWrap {
	gap: 8px;
	width: 100%;
	display: flex;
	flex-direction: column;
	label {
		font-size: 13px;
		color: $adminDarkColor;
	}
	.textareaSubWrap {
		padding: 6px;
		border-radius: 6px;
		border: 1px solid $adminDarkestColor;
	}
}

.btns {
	gap: 12px;
	width: 100%;
	display: flex;
}
