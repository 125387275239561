@import '../../styles/consts.scss';

.title {
  font-size: 24px;
  margin-right: 32px;
}

.main {
  border-radius: 16px;
  padding: 28px 28px 72px 28px;
  background-color: $adminBgLightest;
}

.inputWrap {
  margin-top: 28px;
  max-width: 560px;
  label {
    color: $adminDarkColor;
  }
  input {
    color: $adminLightColor;
  }
}
.inputWrap.select {
}

.inputWrap:first-child {
  margin-top: 0;
}

.btnGetBotInfo {
  margin-top: 28px;
}

.header {
  gap: 10px;
  display: flex;
  margin-bottom: 18px;
  align-items: center;
  justify-content: space-between;
  button {
    font-size: 14px;
    padding: 9px 18px 8px 18px;
  }
}

.headerLeft {
  gap: 10px;
  display: flex;
  align-items: center;
}

.btnDel {
  gap: 5px;
  display: flex;
  align-items: center;
  span {
    margin-top: 2px;
  }
}

.infoList {
  gap: 16px;
  display: flex;
  max-width: 400px;
  margin-top: 28px;
  flex-direction: column;
  &.loading {
    opacity: 0.6;
  }
}

.infoItem {
  gap: 20px;
  display: flex;
  justify-content: space-between;
  .name {
    max-width: 50%;
    color: $adminDarkColor;
  }
  .value {
    max-width: 50%;
    text-align: right;
    color: $adminLightColor;
  }
}

.popupDelBot {
  &.success {
    .title {
      margin-bottom: 32px;
    }
    button {
      margin: auto;
      display: block;
    }
  }
  .btnList {
    gap: 10px;
    display: flex;
    margin-top: 28px;
  }
  button {
    padding: 9px 14px 8px 14px;
  }
  .title {
    margin: 0;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .desc {
    margin-bottom: 28px;
    span {
      font-weight: 600;
      color: $adminBrightColor;
    }
  }
  label {
    color: $adminDarkColor;
  }
}
