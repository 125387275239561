$transition: all 0.25s ease-in-out;

$darkColor: #262c44;
$fontGrey: #636672;

// $adminBg: #1e1d2a;
$adminBg: #272530;

$adminBgDark: #272530;
$adminBgLight: #252736;
$adminBgDarkest: #302f38;
$adminBgLightest: #302f38;

$adminDarkestColor: #45444c;
$adminDarkColor: #8a8a8a;
$adminLightColor: #fff;

$adminBrightColor: #3e99ff;

$red: #ff453a;
$green: #32d74b;

$trans: all ease-in-out 0.2s;

.scroll {
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-track {
		border-radius: 5px;
		background-color: #45444c;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background-color: $adminDarkColor;
	}
}

.btnAdd {
	gap: 6px;
	width: 100%;
	border: none;
	display: flex;
	font-size: 14px;
	padding: 10px 35px;
	border-radius: 6px;
	align-items: center;
	justify-content: center;
	color: $adminLightColor;
	border: 1px solid #ffffff1a;
	background-color: #ffffff1a;
	svg {
		width: 10px;
		height: 10px;
		flex-shrink: 0;
	}
	path {
		fill: $adminLightColor;
	}
	p {
		white-space: nowrap;
	}
}

.btnTransp,
.btnGrey,
.btnLight,
.btnDark,
.btnRed {
	gap: 0.5rem;
	display: flex;
	font-size: 14px;
	padding: 8px 18px;
	border-radius: 8px;
	position: relative;
	justify-content: center;
	color: $adminLightColor;
	border: 1px solid transparent;
	background-color: $adminBrightColor;
	&:disabled {
		cursor: default;
		pointer-events: none;
		border-color: #45444c;
		background-color: #45444c;
	}
	&.loading {
		color: #00000000;
		pointer-events: none;
		@keyframes rotation {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}
}

.btnGrey {
	background-color: #ffffff1a;
	border: 1px solid #ffffff1a;
}

.btnTransp {
	border: none;
	background-color: #00000000;
}

.btnTransp:active,
.btnTransp:hover {
	border: none;
	background-color: #ffffff14;
}

.btnGrey:active,
.btnGrey:hover {
	// background-color: #ffffff1a;
	border: 1px solid #ffffff;
}

.btnRed {
	color: #ff453a;
	background-color: #ff453a1a;
	border: 1px solid #ff453a1a;
}
.btnRed:hover,
.btnRed:active {
	border: 1px solid #ff453a;
}

.btnDark {
	background-color: #3e99ff1a;
	border: 1px solid #3e99ff1a;
}
.btnDark:hover {
	border-color: $adminBrightColor;
}

.btnTransp.add,
.btnGrey.add,
.btnDark.add {
	gap: 6px;
	width: auto;
	display: flex;
	align-items: center;
}

.btnTransp.add::before,
.btnGrey.add::before,
.btnDark.add::before {
	content: "";
	width: 10px;
	height: 10px;
	display: block;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../imgs/plus.svg);
}

.btnDragAndDrop {
	cursor: grab;
	width: 2rem;
	height: 2rem;
	display: block;
	padding: 0.5rem;
	svg {
		width: 100%;
		height: 100%;
	}
	path {
		transition: $transition;
		fill: $adminDarkColor;
	}
}
.btnDragAndDrop:hover {
	path {
		fill: $adminLightColor;
	}
}
.btnDragAndDrop:active {
	cursor: grabbing;
}

$colors: (
	1: #003f5c,
	2: #2f4b7c,
	3: #665191,
	4: #a05195,
	5: #d45087,
	6: #f95d6a,
	7: #ff7c43,
	8: #ffa600,
	9: #003f5c,
	10: #361855,
	11: #281b41,
	12: #a0515e,
	13: #d45087,
	14: #a94d78,
	15: #ff7c43,
	16: #91ff00,
);

@media (max-width: 1000px) {
	.btnTransp,
	.btnGrey,
	.btnLight,
	.btnDark,
	.btnRed {
		font-size: 14px;
		padding: 8px 4px;
	}
	.selectBtn {
		padding: 8px 4px;
	}
}
