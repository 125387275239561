@import "./consts.scss";

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-gutter: stable;
}

body,
html {
	min-height: 100dvh;
	font-family: "Inter", sans-serif;
}

#root {
	min-height: 100vh;
}

li {
	list-style: none;
}

button {
	border: none;
	cursor: pointer;
	font-size: 1rem;
	line-height: 100%;
	background-color: transparent;
	transition: all 0.3s ease-in-out;
	font-family: "Inter", sans-serif;
}

a {
	text-decoration: none;
	color: $adminBrightColor;
}

a:visited {
	// color: $fontGrey;
	color: $adminBrightColor;
}

textarea,
input {
	background-color: transparent;
	font-family: "Inter", sans-serif;
	&::placeholder {
		font-size: 1rem;
		color: $adminDarkColor;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: all 5000s ease-in-out 0s;
}

// input:-webkit-autofill {
//   transition: all 5000s ease-in-out 0s;
// }

input:focus,
input:hover {
	background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	display: none;
	-webkit-appearance: none;
	margin: 0;
}

body {
	font-size: 16px;
	// overflow-y: scroll;
	color: $adminLightColor;
	background-color: #fff;
}

.row {
	display: flex;
	align-items: center;
}

textarea {
	width: 100%;
	border: none;
	resize: none;
	outline: none;
	font-size: 1rem;
	min-height: 82px;
	padding: 6px 8px;
	color: $adminLightColor;
	background-color: transparent;
}

.gap-1 {
	gap: 1rem;
}

.gap-15 {
	gap: 1.5rem;
}

.btn {
	padding: 13px;
	color: #fff;
	border-radius: 40px;
	background-color: $darkColor;
}

.btn:visited {
	color: #fff;
}

.btn:disabled {
	cursor: default;
	background-color: #ced3e0;
}

.btn span {
	margin-top: 2px;
}

.icon {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.btnCopy {
	width: 14px;
	height: 14px;
	path {
		transition: $transition;
	}
	&:hover {
		path {
			stroke: $adminLightColor;
		}
	}
}

.page {
	flex: 1;
	max-width: 100%;
	// overflow-x: hidden;
	padding-left: 30px;
	padding-right: 50px;
}

.scroll {
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-track {
		border-radius: 5px;
		background-color: #45444c;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background-color: $adminDarkColor;
	}
}

.btnBack {
	gap: 6px;
	display: flex;
	margin-bottom: 8px;
	align-items: center;
	path {
		transition: $transition;
		stroke: $adminDarkColor;
	}
	span {
		transition: $transition;
		color: $adminDarkColor;
	}
}
.btnBack:hover {
	span {
		color: $adminLightColor;
	}
	path {
		stroke: $adminLightColor;
	}
}

.formCodeInput {
	margin: 0 auto;
	position: relative;
	&::before {
		content: "-";
		top: 40%;
		left: 49.2%;
		position: absolute;
	}
	input:nth-child(3) {
		margin-right: 25px;
	}
	input:nth-child(3),
	input:nth-child(6) {
		border-radius: 0 5px 5px 0;
	}
	input:nth-child(4),
	input:nth-child(1) {
		border-radius: 5px 0 0 5px;
	}
	input {
		min-width: 0;
		width: 80px;
		height: 80px;
		border: none;
		font-size: 36px;
		color: $darkColor;
		text-align: center;
		border: 1px solid #ccc;
		background-color: #fff;
		&:hover,
		&:focus {
			z-index: 99;
			outline: none;
			background-color: #fff !important;
			border: 2px solid $adminBrightColor;
		}
	}
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

.mobile {
	display: none;
}

@media (max-width: 1000px) {
	.desktop {
		display: none !important;
	}
	.mobile {
		display: block;
	}
	html,
	body {
		width: auto;
		overflow-x: hidden;
	}
	.recharts-responsive-container {
		height: 250px !important;
	}
}
