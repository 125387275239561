@import "../../styles/consts.scss";
@import "../../styles/index.scss";

.page {
	overflow-x: hidden;
}

.header {
	display: flex;
	justify-content: space-between;

	&__btnBack,
	&__btnNext {
		top: 50%;
		z-index: 9;
		width: 32px;
		height: 32px;
		position: absolute;
		border-radius: 100px;
		background-size: 50%;
		transform: translateY(-50%);
		background-position: center;
		background-repeat: no-repeat;
		background-color: #3e99ff1a;
		border: 1px solid #3e99ff1a;
	}
	&__btnBack {
		left: -16px;
		background-image: url(../../imgs/arrowPrev.svg);
	}
	&__btnNext {
		right: -16px;
		background-image: url(../../imgs/arrowNext.svg);
	}
	&__listWrap {
		flex: 1;
		display: flex;
		max-width: 70%;
		position: relative;
		align-items: center;
	}
	&__list {
		width: 100%;
	}
	&__item {
		gap: 20px;
		width: auto;
		display: flex;
		flex-shrink: 0;
		cursor: pointer;
		padding: 10px 12px;
		border-radius: 12px;
		align-items: flex-start;
		border: 1px solid transparent;
		justify-content: space-between;
		background-color: $adminBgLightest;
		p {
			font-size: 15px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			word-break: break-all;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
		}
		button path {
			transition: $transition;
			stroke: $adminDarkColor;
		}
		button:active,
		button:hover {
			path {
				stroke: $adminLightColor;
			}
		}
		&__control {
			gap: 10px;
			display: flex;
			align-items: center;
		}
		&__btnDel {
			width: 18px;
			height: 18px;
			flex-shrink: 0;
			svg {
				width: 100%;
				height: 100%;
			}
			z-index: 9;
		}
		&.actived {
			border-color: $adminBrightColor;
		}
	}
	&__btns {
		gap: 10px;
		display: flex;
		justify-content: flex-end;
		button {
			width: 123px;
			flex-shrink: 0;
			border-radius: 16px;
			border: 1px solid transparent;
			background-color: $adminBgLightest;
			&:disabled {
				cursor: default;
			}
			p {
				width: 100%;
				font-size: 14px;
				text-align: center;
				color: $adminLightColor;
			}
			.header__btn__imgWrap {
				width: 18px;
				height: 18px;
				margin: 0 auto;
				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	.header__btn {
		gap: 7px;
		display: flex;
		flex-shrink: 0;
		padding: 10px 12px;
		border-radius: 12px;
		align-items: center;
		border: 1px solid transparent;
		background-color: $adminBgLightest;
		&:disabled {
			cursor: default;
		}
		p {
			width: 100%;
			font-size: 14px;
			text-align: center;
			color: $adminLightColor;
		}
		.header__btn__imgWrap {
			width: 18px;
			height: 18px;
			margin: 0 auto;
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
}

.main {
	margin-top: 18px;
	//   overflow: hidden;
	border-radius: 16px;
	padding: 18px 8px 18px 28px;
	background-color: $adminBgLightest;
	&__top__wrap {
		gap: 20px;
		display: flex;
		justify-content: space-between;
	}
	&__top__btns {
		gap: 12px;
		// display: flex;
		margin-top: -7px;
		// align-items: flex-start;
		display: grid;
		align-self: flex-start;
		grid-template-columns: auto auto;
	}
	&__top {
		gap: 24px;
		width: 100%;
		display: flex;
		max-width: 800px;
		&__textarea {
			textarea {
				min-height: 102px;
			}
		}
		&__img {
			width: 119px;
			height: 119px;
			margin-top: 21px;
		}
	}
	&__btnItemWrap {
		display: flex;
		align-items: flex-end;
	}
	&__btnItemDel {
		padding: 12px;
		path {
			fill: #8a8a8a;
		}
		&:hover {
			path {
				fill: #a9a9a9;
			}
		}
	}
	&__btnsWrap {
		margin-top: 18px;
		align-items: center;
		.btns {
			gap: 12px;
			row-gap: 12px;
			display: grid;
			overflow-y: auto;
			padding-bottom: 5px;
			align-items: center;
			margin-right: 20px;
			grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		}
		.btnsInRow1 {
			grid-template-columns: repeat(1, 230px);
		}
		.btnsInRow2 {
			grid-template-columns: repeat(2, 230px);
		}
		.btnsInRow3 {
			grid-template-columns: repeat(3, 230px);
		}
		.btnsInRow4 {
			grid-template-columns: repeat(4, minmax(200px, 1fr));
		}
		.btnsInRow5 {
			grid-template-columns: repeat(5, minmax(200px, 1fr));
		}
		.btnsInRow6 {
			grid-template-columns: repeat(6, minmax(200px, 1fr));
		}
		.input {
			width: 100%;
			label {
				font-size: 13px;
				color: #8a8a8a;
			}
			input {
				font-size: 13px;
			}
		}
		.btnAdd {
			@extend .btnTransp, .add;
			width: 100%;
			height: 44px;
			align-self: flex-end;
			justify-content: center;
		}
	}
	&__items {
		gap: 10px;
		display: flex;
		margin-top: 18px;
		margin-right: 20px;
		flex-direction: column;
		.itemKey {
			font-size: 12px;
			margin-left: 8px;
			padding: 4px 6px;
			border-radius: 8px;
			background-color: #3e99ff66;
		}
		.itemHeader {
			display: flex;
			align-items: center;
		}
		.item {
			border-radius: 10px;
			background-color: $adminBgDark;
			&.opened {
				.header .arrowWrap {
					transform: rotate(0);
				}
			}
			.header {
				cursor: pointer;
				padding: 2px 18px;
				align-items: center;
				.settings {
					gap: 10px;
					align-items: center;
					display: flex;
				}
				.switcher {
					margin-right: 20px;
					label {
						font-size: 13px;
					}
				}

				.arrowWrap {
					display: flex;
					align-items: center;
					transition: $transition;
					transform: rotate(180deg);
					path {
						fill: $adminDarkColor;
						transition: $transition;
					}
				}
				.btnDel {
					padding: 0.5rem;
					margin: -0.5rem;
					margin-right: 0;
					path {
						transition: $transition;
						stroke: $adminDarkColor;
					}
					&:hover {
						path {
							stroke: #fff;
						}
					}
				}
			}
			.main {
				gap: 8px;
				margin: 0;
				display: flex;
				flex-direction: column;
				background: none;
				border-radius: 0;
				padding: 14px 18px 18px 18px;
				border-top: 1px solid #3d3b45;
				&__action {
					border-radius: 10px;
					background-color: #302f38;
					&__header {
						display: flex;
						font-size: 13px;
						padding: 5px 12px;
						align-items: center;
						background-color: #45444c;
						border-radius: 10px 10px 0 0;
						justify-content: space-between;
					}
					&__main {
						display: grid;
						padding: 0 14px;
						column-gap: 18px;
						grid-template-rows: auto 1fr;
						grid-template-columns: 0.6fr 0.4fr;
					}
					&__btnDel {
						margin: -0.5rem;
						padding: 0.5rem;
						path {
							stroke: transparent;
							fill: #8a8a8a;
						}
						&:hover {
							path {
								fill: #b5b5b5;
							}
						}
					}
				}
				.top {
					gap: 18px;
					display: flex;
					margin-top: 14px;
					flex-wrap: wrap;
				}
				.middle {
					gap: 18px;
					display: flex;
					margin-top: 14px;
					margin-bottom: 14px;
					.textarea {
						width: 400px;
					}
					.textareaWrap textarea {
						// height: 32px;
						min-height: 60px;
					}
					.imgInput {
						width: 50px;
						height: 50px;
						margin-top: 22px;
					}
					.btnRowList {
						display: flex;
						margin-top: 24px;
						align-items: center;
						button {
							gap: 4px;
							padding: 9px;
							display: flex;
							color: #fff;
							font-size: 14px;
						}
					}
				}
				.bottom {
					width: 100%;
					grid-area: 1/2/3/3;
					padding: 14px 0 18px 14px;
					border-left: 1px solid #42414a;
				}
			}
		}
	}
}

.popupUpdatingsContent {
	.checkbox {
		margin-top: 2rem;
		p {
			margin: 0;
		}
	}
}

.popupUpdatings,
.popupSettings {
	width: 560px;
	&.Content {
		overflow: visible;
	}
	.title {
		font-size: 20px;
		font-weight: 500;
		text-align: center;
	}
	.select,
	.input {
		margin-top: 18px;
		label {
			font-size: 13px;
			color: $adminDarkColor;
		}
		&.mini {
			width: 140px;
			margin-top: 0;
		}
	}
	.bottomRow {
		gap: 24px;
		display: flex;
		margin-top: 24px;
		align-items: center;
	}
	.checkbox {
		text-align: left;
		align-items: center;
		margin-top: 20px;
		p {
			font-size: 13px;
			color: $adminDarkColor;
		}
	}
	.btns {
		gap: 12px;
		display: flex;
		margin-top: 24px;
	}
}

.input,
.select {
	width: 300px;
	&.parameter {
		max-width: 150px;
	}
	button,
	div {
		border-radius: 6px;
	}
	button {
		color: #fff;
		padding: 14px;
	}
	label {
		font-size: 13px;
		color: $adminDarkColor;
	}
	input {
		font-size: 13px;
	}
}

.btnAddAction {
	gap: 5px;
	display: flex;
	align-items: center;
}

.switcher.restart {
	margin-left: 30px;
	margin-right: 0 !important;
}
