@import '../../styles/consts.scss';

// pie chart
// .recharts-layer {
//   .recharts-bar-rectangle,
//   .recharts-pie-sector {
//     @each $index, $color in $colors {
//       &:nth-child(#{$index}) {
//         circle,
//         path:not(.none-fill) {
//           fill: #{$color};
//         }
//         circle,
//         path.none-fill {
//           stroke: #{$color};
//         }
//       }
//     }
//   }
// }

.recharts-tooltip-cursor {
  fill: $trans;
}
