@import '../../styles/consts.scss';

.main {
  height: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: $adminBgDark;
}

.wrapper {
  width: 100%;
  max-width: 1100px;
  padding: 98px 50px 50px 50px;
}

.logoWrap {
  width: 162px;
  height: 162px;
  margin: 0 auto;
  margin-top: 80px;
  object-fit: contain;
}

.tg_link {
  display: block;
  font-weight: 600;
  color: $adminBrightColor;
  margin: 20px auto;
  text-align: center;
  width: max-content;
  text-decoration: underline $adminBrightColor;
}
.inputWrap {
  margin: 0 auto;
  max-width: 460px;
  margin-top: 28px;
}
.inputWrap:focus {
  input {
    border-color: #000;
  }
}
.title {
  font-size: 32px;
  margin-top: 28px;
  font-weight: 700;
  text-align: center;
  color: $adminLightColor;
}
.btnWrap {
  gap: 10px;
  display: flex;
  margin: 0 auto;
  margin-top: 28px;
  justify-content: center;
}
.btnWrap.oneWidth {
  max-width: 280px;
  button {
    width: 100%;
  }
}
.btnWrap.vert {
  align-items: center;
  flex-direction: column;
}
.text {
  margin: 0 auto;
  margin-top: 18px;
  max-width: 500px;
  text-align: center;
  color: $adminLightColor;
}

.btnForm {
  padding: 11.5px 40px;
}

.btnForm.light {
  padding-left: 0;
  padding-right: 0;
  color: $adminDarkColor;
  transition: $transition;
  background-color: transparent;
}
.btnForm.light:hover,
.btnForm.light:active {
  color: $adminLightColor;
}

.iconMes {
  width: 22px;
  height: 1rem;
  background-image: url('data:image/svg+xml,<svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.2008 0.72477C21.8906 0.288389 21.3691 0 20.7805 0H2.21861C1.64237 0 1.13284 0.278148 0.820312 0.699334L11.5336 9.28228L22.2008 0.72477Z" fill="white"/><path d="M0.5 2.16223V14.3482C0.5 15.2567 1.27358 15.9999 2.21907 15.9999H20.7809C21.7264 15.9999 22.5 15.2567 22.5 14.3482V2.20617L11.534 11.0028L0.5 2.16223Z" fill="white"/></svg>');
}

.iconTg {
  width: 20px;
  height: 1rem;
  background-image: url('data:image/svg+xml,<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.943 1.4563L16.9248 15.0342C16.6969 15.9923 16.1033 16.2308 15.2596 15.7797L10.6606 12.5469L8.44178 14.5831C8.19604 14.8175 7.99102 15.0131 7.5174 15.0131L7.84815 10.5455L16.3712 3.19877C16.7419 2.88394 16.2905 2.70881 15.7954 3.02432L5.25858 9.35363L0.722389 7.99891C-0.264144 7.7052 -0.282003 7.05783 0.928125 6.60602L18.6707 0.0852222C19.4922 -0.208483 20.2109 0.258992 19.943 1.4563Z" fill="white"/></svg>');
}

.agreement {
  font-size: 13px;
  margin: 0 auto;
  margin-top: 10px;
  max-width: 463px;
  text-align: center;
  a,
  a:visited {
    color: $adminBrightColor;
    text-decoration: underline $adminBrightColor;
  }
}

.btnLink,
.link {
  display: block;
  margin: 0 auto;
  // color: $fontGrey;
  margin-top: 48px;
  text-align: center;
}

.btnLink {
  margin-top: 90px;
}

.link {
  color: $darkColor;
  text-decoration: underline $darkColor;
}

.tokenBtns {
  width: 100%;
  display: flex;
  margin-top: 18px;
  align-items: center;
  justify-content: space-between;
}

.checkList {
  &__wrap {
    max-width: 600px;
    padding: 38px;
    margin: 0 auto;
    margin-top: 18px;
    border-radius: 20px;
    color: $adminLightColor;
    background: $adminBgDarkest;
  }
  &__title {
    font-size: 30px;
    text-align: center;
    color: $adminLightColor;
  }
  &__list {
    gap: 16px;
    display: flex;
    margin-top: 18px;
    flex-direction: column;
  }
  &__item {
    text-align: center;
    a,
    a:visited {
      font-weight: 600;
      color: $adminBrightColor;
      text-decoration: underline $adminBrightColor;
    }
  }
  &__btn {
    color: #fff;
    display: block;
    margin: 0 auto;
    margin-top: 28px;
    width: fit-content;
    &:visited {
      color: #fff;
    }
  }
}

.cookie {
  &__wrap {
    width: 100%;
    padding: 0 50px;
    max-width: 1100px;
    margin-bottom: 40px;
    // position: fixed;
    // bottom: 40px;
  }
  &__wrap.hidden {
    animation: disappearance 0.3s ease-in;
    display: none;
  }
  width: 100%;
  padding: 8px;
  display: flex;
  margin-top: 0;
  border-radius: 8px;
  align-items: center;
  background-color: #3e99ff1a;
  border: 1px solid #3e99ff1a;
  justify-content: space-between;
  p {
    margin: 0 22px;
    font-weight: 500;
    line-height: 100%;
  }
  &__btnWrap {
    gap: 10px;
    display: flex;
    align-items: center;
  }

  .btnCookie {
    padding: 9px 16px 8.5px 16px;
  }
  .btnCookie.light {
    background: transparent;
  }
}

%progressRound {
  width: 10px;
  content: '';
  bottom: -3px;
  height: 10px;
  position: absolute;
  border-radius: 100%;
}

.input {
  &__code {
    &__wrap {
      gap: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__subWrap {
      width: 240px;
      height: 80px;
      padding: 1px;
      display: flex;
      border-radius: 5px;
    }
    &__symbolWrap {
      height: 100%;
      display: flex;
      overflow: none;
      margin-right: -2px;
      align-items: center;
      justify-content: center;
      background-color: #fff;
    }
    &__symbol {
      min-width: 0;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 36px;
      color: $darkColor;
      text-align: center;
      border: 2px solid #ccc;
    }
    &__symbol:focus {
      z-index: 99;
      outline: none;
      border: 2px solid $adminBrightColor;
    }
    &__symbolWrap:first-child {
      border-radius: 4px 0 0 4px;
      .input__code__symbol {
        border-radius: 4px 0 0 4px;
      }
    }
    &__symbolWrap:last-child {
      border-radius: 0 4px 4px 0;
      .input__code__symbol {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  &__input::placeholder {
    font-size: 1rem;
    color: $adminDarkColor;
  }
  &__input:focus {
    outline: none;
  }
  &__input {
    font-size: 1rem;
    min-width: 412px;
    border-radius: 6px;
    padding: 12.5px 16px;
    border: 1px solid #afb4c1;
    transition: all 0.3s ease-in-out;
  }
  &__label {
    width: 100%;
    margin-bottom: 6px;
    display: inline-block;
  }
  &__wrap {
    margin: 0 auto;
    margin-top: 28px;
    width: 100%;
    max-width: 506px;
  }
  &__input.error {
    border-color: #ff3737;
  }
  &__textError {
    font-size: 14px;
    color: #ff3737;
    position: absolute;
  }
}

.progress {
  //   &__wrap {
  //   }
  &__text {
    font-size: 14px;
    color: $adminLightColor;
    margin-left: -5px;
    margin-bottom: 5px;
  }
  &__bar::before {
    left: -5px;
  }
  &__bar::after {
    right: -5px;
  }
  &__bar::before,
  &__bar::after {
    @extend %progressRound;
    background-color: $adminLightColor;
  }
  &__bar {
    height: 4px;
    position: relative;
    background-color: $adminLightColor;
    &__aggregate {
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 99;
      width: 100%;
      transition: all;
      position: absolute;
      background-color: $adminBrightColor;
      transition: max-width 0.5s ease-in-out;
    }
    &__aggregate::after,
    &__aggregate::before {
      right: -5px;
      @extend %progressRound;
      background-color: $adminBrightColor;
    }
    &__aggregate::after {
      right: -5px;
    }
    &__aggregate::before {
      left: -5px;
    }
  }
}

@keyframes disappearance {
  from {
    display: block;
    opacity: 1;
  }
  to {
    display: none;
    opacity: 0;
  }
}
