@import '../../styles/index.scss';
@import '../../styles/consts.scss';

.header {
  display: flex;
  padding: 6px 33.5px;
  align-items: center;
  border-radius: 0 0 20px 20px;
  justify-content: space-between;
  background-color: $adminBgDarkest;
  &__userSelect {
    border: none;
  }
  &__right,
  &__left {
    display: flex;
    align-items: center;
  }
  &__right {
    &__btn {
      padding: 16.5px;
      path {
        transition: $transition;
      }
    }
    &__btn.fill {
      path {
        fill: $adminDarkColor;
      }
    }
    &__btn.stroke {
      path {
        stroke: $adminDarkColor;
      }
    }
    &__btn.fill:hover {
      path {
        fill: $adminLightColor;
      }
    }
    &__btn.stroke:hover {
      path {
        stroke: $adminLightColor;
      }
    }
  }
  &__selectListWrap {
    gap: 42px;
    display: flex;
    margin-left: 42.5px;
    align-items: center;
    button {
      min-height: 32px;
    }
  }
  &__select {
    width: 230px;
  }
  &__logo {
    width: 72px;
    height: 72px;
  }
  &__btnAdd {
    @extend .btnAdd;
    gap: 10px;
    width: auto;
    font-size: 13px;
    padding: 8px 10px;
    border-radius: 10px;
    align-items: center;
    color: $adminDarkColor;
    justify-content: center;
    background-color: transparent;
    border: 1px solid $adminDarkestColor;
    svg {
      width: 12px;
      height: 12px;
    }
    path {
      transition: $transition;
      fill: $adminDarkColor;
    }
    &:hover {
      color: $adminLightColor;
      path {
        fill: $adminLightColor;
      }
    }
    &:disabled {
      opacity: 0.75;
      cursor: default;
      pointer-events: none;
    }
  }
}
