@import '../../styles/consts.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btns {
    gap: 12px;
    display: flex;
  }
  .title {
    font-size: 24px;
    font-weight: 600;
  }
}

.main {
  display: grid;
  margin-top: 18px;
  border-radius: 16px;
  padding: 0 8px 0 28px;
  background-color: $adminBgLightest;
  grid-template-columns: repeat(2, 1fr);
  .left {
    padding: 18px 0;
    padding-right: 24px;
    border-right: 1px solid #42414a;
  }
  .right {
    max-height: 80vh;
    overflow-x: hidden;
    margin: 18px 0;
    padding-left: 24px;
    padding-right: 18px;
  }
}

.subTitleWrap {
  gap: 10px;
  display: flex;
  margin-top: -6px;
  align-items: center;
}

.subTitle {
  font-size: 15px;
  font-weight: 600;
}

.inputList {
  gap: 14px;
  display: flex;
  margin-top: 18px;
  flex-direction: column;
}

.btnAdd {
  gap: 5px;
  display: flex;
  align-items: center;
}

.inputFlex {
  gap: 18px;
  display: flex;
  align-items: flex-start;
  .imgInput {
    margin-top: 22px;
  }
  .note {
    font-size: 13px;
    margin-top: 32px;
    color: #8a8a8a;
  }
}

.textarea {
  width: 360px;
  textarea {
    height: 30px;
    min-height: 30px;
  }
}

.settingsTitle {
  margin-top: 28px;
}

// jokes

.joke {
  border-radius: 10px;
  background-color: #272530;
}
.jokeList {
  gap: 9px;
  display: flex;
  margin-top: 9px;
  flex-direction: column;
}

.jokeHeader {
  display: flex;
  padding: 5px 22px;
  justify-content: space-between;
  border-bottom: 1px solid #45444c;
  .btnDel {
    padding: 10px;
    margin: -10px;
    path {
      stroke: #8a8a8a;
    }
  }
}
.jokeMain {
  padding: 14px 12px;
}

.jokeSubBlock {
  gap: 18px;
  padding: 12px;
  display: flex;
  border-radius: 10px;
  background-color: #302f38;
}

.jokeSubBlock.transp {
  padding: 18px 0 0 0;
  background-color: transparent;
}

.switchType {
  gap: 6px;
  display: flex;
  margin: 15px auto;
  justify-content: center;
  button {
    color: #fff;
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 68px;
    background-color: #42424b;
    border: 1px solid #ffffff1a;
    &.active {
      background-color: #3e99ff;
    }
  }
}

// tasks

.taskTitle {
  margin-top: 30px;
}

.taskList {
  gap: 14px;
  display: flex;
  margin-top: 12px;
  flex-direction: column;
}

.taskMain {
  gap: 18px;
  display: flex;
  align-items: flex-start;
  .btnDel {
    padding: 10px;
    margin-top: 25px;
    path {
      stroke: #8a8a8a;
    }
  }
}
