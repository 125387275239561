@import "../../styles/consts.scss";

.main {
  display: flex;
  padding-top: 40px;
  padding-bottom: 80px;
  align-items: flex-start;
  color: $adminLightColor;
  &__wrap {
    height: 100%;
    min-height: 100dvh;
    position: relative;
    background-color: $adminBg;
  }
}

.popupAddCompany {
  width: 634px;
  max-width: 100%;
  .btnWrap {
    gap: 10px;
    display: flex;
    margin-top: 28px;
  }
  label {
    margin-top: 20px;
  }
}
